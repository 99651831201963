import React from "react";

import { t } from "i18next";
import swal from "@sweetalert/with-react";

import { setIsLoading } from "../../../store/project";
import { DownloadIcon } from "@aureskonnect/react-ui";

import classnames from "classnames";

type UploaderPropsType = {
    active: boolean;
    file: any;
    setFile: any;
    ticketType: string;
    disabled?: boolean;
};

export function Uploader({
    active,
    file,
    setFile,
    ticketType,
    disabled,
}: UploaderPropsType): JSX.Element {
    const validUploadedTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "video/mp4",
        "video/webm",
        "image/webp",
    ];

    const acceptedInputTypes = validUploadedTypes?.join(",");

    const [displayImage, setDisplayImage] = React.useState("");

    async function handleUpdatingUploadDataOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const fileTarget = event.target?.files;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/upload/${ticketType}`;
        if (fileTarget !== null) {
            const formData = new FormData();
            formData.append("file", (fileTarget as FileList)[0]);
            if (
                !validUploadedTypes.includes((fileTarget as FileList)[0].type)
            ) {
                return swal({
                    icon: "error",
                    content: <p>{t("File type is not valid")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            }

            if ((fileTarget as FileList)[0]) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    setDisplayImage(reader.result as any);
                };

                reader.readAsDataURL((fileTarget as FileList)[0]);
            }
            try {
                const response = await fetch(apiUrl, {
                    body: formData,
                    method: "PUT",
                });
                const result = await response.json();

                if (result.error) {
                    throw new Error(result.message);
                }
                setFile(result.data);

                setIsLoading(false);

                swal({
                    icon: "success",
                    content: <p>{t("Logo loaded successfully.")}</p>,
                    buttons: false,
                    timer: 2000,
                });

                setTimeout(() => {
                    setDisplayImage(result.data);
                }, 3000);
            } catch (error: any) {
                console.error("Upload failed:", error);
                setIsLoading(false);

                swal({
                    icon: "error",
                    content: <p>{t(error.message)}</p>,
                    buttons: false,
                    timer: 2000,
                });
            }
        }
    }
    React.useEffect(() => {
        setDisplayImage(file);
        // eslint-disable-next-line
    }, [file]);

    return (
        <div
            className="d-flex align-items-center"
            style={{
                gap: "5px",
            }}
        >
            <div
                className="text-truncate"
                style={{
                    width: active ? "150px" : "unset",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
            >
                {t("Load logo")}
            </div>
            {active && (
                //eslint-disable-next-line
                <img
                    src={displayImage === "" ? file : displayImage}
                    height={50}
                    width={50}
                />
            )}
            <label>
                <DownloadIcon
                    height={5}
                    width={5}
                    style={{
                        cursor: "pointer",
                        minWidth: "40px",
                        minHeight: "25px",
                        position: "relative",
                    }}
                    className={`${classnames("ml-2 afa_btn_uploadLogoClient", {
                        "not-allowed-icon__clz": !active,
                    })}`}
                />
                <input
                    accept={acceptedInputTypes}
                    multiple={false}
                    type="file"
                    title="input"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleUpdatingUploadDataOnChangeEvent(event);
                    }}
                    onClick={(event: any) => {
                        event.currentTarget.value = "";
                    }}
                    disabled={disabled}
                />
            </label>
        </div>
    );
}
