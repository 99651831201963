import { proxy } from "valtio";

type StoreType = {
    dataGeneral: any;
    dataDivers: any;
    dataKitchenTicket: any;
    dataCustomerTicket: any;
    dataFirstTicket: any;
    dataSecondTicket: any;
    dataThreedTicket: any;
    dataFourthTicket: any;
    dataFifthTicket: any;
    isValidated: boolean;
    checkboxIcon: boolean;
    checkboxIconSecondModel: boolean;
    checkboxIconThreedModel: boolean;
    checkboxIconFourthModel: boolean;
    checkboxIconFifthModel: boolean;
    printerConfigModalOpened: boolean;
    clickValidatePrinter: boolean;
    printerConfigModalData: any;
    salesModes: any;
    isValidateButtonEnabled: boolean;
};

export const store = proxy<StoreType>({
    salesModes: {},
    dataGeneral: {},
    dataDivers: {},
    dataKitchenTicket: {},
    dataCustomerTicket: {},
    dataFirstTicket: {},
    dataSecondTicket: {},
    dataThreedTicket: {},
    dataFourthTicket: {},
    dataFifthTicket: {},
    isValidated: false,
    checkboxIcon: false,
    checkboxIconSecondModel: false,
    checkboxIconThreedModel: false,
    checkboxIconFourthModel: false,
    checkboxIconFifthModel: false,
    printerConfigModalOpened: false,
    clickValidatePrinter: false,
    printerConfigModalData: {},
    isValidateButtonEnabled: false,
});

export function setSalesModes(salesModes: any): void {
    store.salesModes = salesModes;
}
export function setPrinterConfigModalOpened(printerConfigModal: any): void {
    store.printerConfigModalOpened = printerConfigModal;
}
export function setPrinterConfigModalData(printerConfigModalData: any): void {
    store.printerConfigModalData = printerConfigModalData;
}
export function setClickValidatePrinter(clickValidatePrinter: any): void {
    store.clickValidatePrinter = clickValidatePrinter;
}

export function setDataGeneral(dataGeneral: any): void {
    store.dataGeneral = dataGeneral;
}
export function setDivers(dataDivers: any): void {
    store.dataDivers = dataDivers;
}
export function setDataKitchenTicket(dataKitchenTicket: any): void {
    store.dataKitchenTicket = dataKitchenTicket;
}
export function setDataCustomerTicket(dataCustomerTicket: any): void {
    store.dataCustomerTicket = dataCustomerTicket;
}

export function setDataFirstTicket(dataFirstTicket: any): void {
    store.dataFirstTicket = dataFirstTicket;
}
export function setDataSecondTicket(dataSecondTicket: any): void {
    store.dataSecondTicket = dataSecondTicket;
}
export function setDatathreedTicket(dataThreedTicket: any): void {
    store.dataThreedTicket = dataThreedTicket;
}
export function setDataFourthTicket(dataFourthTicket: any): void {
    store.dataFourthTicket = dataFourthTicket;
}
export function setDataFifthTicket(dataFifthTicket: any): void {
    store.dataFifthTicket = dataFifthTicket;
}
export function setIsValidateClicked(isValidated: boolean): void {
    store.isValidated = isValidated;
}
export function setCheckboxIcon(checkboxIcon: boolean): void {
    store.checkboxIcon = checkboxIcon;
}

export function setCheckboxIconSecondModel(
    checkboxIconSecondModel: boolean
): void {
    store.checkboxIconSecondModel = checkboxIconSecondModel;
}
export function setCheckboxIconThreedModel(
    checkboxIconThreedModel: boolean
): void {
    store.checkboxIconThreedModel = checkboxIconThreedModel;
}
export function setCheckboxIconFourthModel(
    checkboxIconFourthModel: boolean
): void {
    store.checkboxIconFourthModel = checkboxIconFourthModel;
}
export function setCheckboxIconFifthModel(
    checkboxIconFifthModel: boolean
): void {
    store.checkboxIconFifthModel = checkboxIconFifthModel;
}
export function setIsValidateButtonEnabled(isValidateButtonEnabled: boolean) {
    store.isValidateButtonEnabled = isValidateButtonEnabled;
}
