import { t } from "i18next";
import React from "react";

export type saleSupportFormType = {
    allDataShops: any;
    isAddSaleSupportClicked: boolean;
    setIsAddSaleSupportClicked: Function;
    saleSupport: React.SetStateAction<any>;
    isAddNewSaleSupportButtonClicked: boolean;
    setSaleSupportData: Function;
    userData: {
        userId: string | number;
        shopId: string | number;
        franchiseId: string | number;
    };
    setLocalFilterActive: Function;
    setLimit: Function;
    isConsult: boolean;
    setIsConsult: Function;
    dataState: any;
};

export const translatedSaleSupport: any = {
    [t("KIOSK")]: {
        fr: "KIOSK",
        en: "KIOSK",
        nl: "KIOSK",
        es: "QUIOSCO",
        pt: "QUIOSQUE",
        it: "KIOSK",
    },
    [t("POS")]: {
        fr: "POS",
        en: "POS",
        nl: "POS",
        es: "POS",
        pt: "POS",
        it: "POS",
    },
    [t("SCO")]: {
        fr: "SCO",
        en: "SCO",
        nl: "SCO",
        es: "SCO",
        pt: "SCO",
        it: "SCO",
    },
    [t("C&C App")]: {
        fr: "Application C&C",
        en: "C&C App",
        nl: "C&C App",
        es: "Aplicación C&C",
        pt: "Aplicativo C&C",
        it: "App C&C",
    },
    [t("C&C Website")]: {
        fr: "Site C&C",
        en: "C&C Website",
        nl: "C&C Website",
        es: "Sitio web C&C",
        pt: "Site C&C",
        it: "Sito web C&C",
    },
    [t("UBER")]: {
        fr: "UBER",
        en: "UBER",
        nl: "UBER",
        es: "UBER",
        pt: "UBER",
        it: "UBER",
    },
    [t("Deliveroo")]: {
        fr: "Deliveroo",
        en: "Deliveroo",
        nl: "Deliveroo",
        es: "Deliveroo",
        pt: "Deliveroo",
        it: "Deliveroo",
    },
    [t("Otter")]: {
        fr: "Otter",
        en: "Otter",
        nl: "Otter",
        es: "Otter",
        pt: "Otter",
        it: "Otter",
    },
};

export const advancedDisplayNameDefault = {
    languages: {
        fr: "",
        en: "",
        nl: "",
        es: "",
        pt: "",
        it: "",
    },
};
const createSaleSupportEntry = (
    shopId: any,
    isActive: Boolean,
    userID: any,
    franchiseID: any,
    operatorID: any,
    uuidUser: any,
    id: any,
    displayName: any,
    tagsData: any,
    saleSupportLabel: any,
    advancedDisplayName: any
) => ({
    userId: userID,
    shopId: [shopId],
    franchiseId: franchiseID,
    operatorId: operatorID,
    uuidUser: uuidUser,
    data: {
        [id]: {
            id: id,
            displayName: displayName,
            tag: tagsData.join(","),
            isArchived: false,
            isActive: isActive,
            support_de_vente: saleSupportLabel,
            shopId: shopId,
            isFavorite: isActive, // Adjust as needed
            advancedDisplayName: advancedDisplayName,
        },
    },
});

export function CreateDataSaleSupport(
    oneShop: boolean,
    isMessageValidateClicked: boolean,
    allDataShops: any,
    userID: any,
    franchiseID: any,
    operatorID: any,
    uuidUser: any,
    id: any,
    displayName: any,
    tagsData: any,
    saleSupportLabel: any,
    advancedDisplayName: any,
    shopId: any,
    saleSupportData: any
) {
    let dataSaleSupport;

    const isActiveFranchise = saleSupportData.length === 0 ? true : false;

    const isActiveShop = allDataShops.length === 0;
    // Create the dataSaleSupport based on conditions
    if (oneShop && isMessageValidateClicked) {
        dataSaleSupport = [
            {
                ...createSaleSupportEntry(
                    shopId,
                    isActiveFranchise,
                    userID,
                    franchiseID,
                    operatorID,
                    uuidUser,
                    id,
                    displayName,
                    tagsData,
                    saleSupportLabel,
                    advancedDisplayName
                ), affectation: true,
            }
        ];
    } else if (oneShop && !isMessageValidateClicked) {
        dataSaleSupport = [
            createSaleSupportEntry(
                "0",
                isActiveShop,
                userID,
                franchiseID,
                operatorID,
                uuidUser,
                id,
                displayName,
                tagsData,
                saleSupportLabel,
                advancedDisplayName
            ),
            createSaleSupportEntry(
                shopId,
                isActiveFranchise,
                userID,
                franchiseID,
                operatorID,
                uuidUser,
                id,
                displayName,
                tagsData,
                saleSupportLabel,
                advancedDisplayName
            ),
        ];
    } else {
        dataSaleSupport = [
            createSaleSupportEntry(
                shopId,
                isActiveFranchise,
                userID,
                franchiseID,
                operatorID,
                uuidUser,
                id,
                displayName,
                tagsData,
                saleSupportLabel,
                advancedDisplayName
            ),
        ];
    }
    return dataSaleSupport;
}

export async function getTag(
    userID: any,
    shopID: any,
    franchiseID: any,
    setTags: Function
) {
    try {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/tag?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&deviceType=tag`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push(element.Designation);
                    }
                    setTags(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    } catch (error) {
        throw error;
    }
}
export async function getTypedTag(
    shopId: any,
    franchiseID: any,
    setTags: Function
) {
    try {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/typed_tag?shopId=${shopId}&franchiseId=${franchiseID}`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push(element.designation);
                    }
                    setTags(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    } catch (error) {
        throw error;
    }
}
export const fechtingLanguageList = async (
    setLanguage: any,
    userID: any,
    shopId: any,
    franchiseID: any
) => {
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const data: any = await (
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
            {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            }
        )
    ).json();

    const lang: any =
        data.data !== undefined
            ? data.data.map((element: any) => {
                return element;
            })
            : [];
    setLanguage(lang);
};
