import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import store, { setIsLoading } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { ConfirmationMessageType } from "../Types";
import NProgressWrapper from "@components/Common/NProgressWrapper";

export function ConfirmationMessage({ data }: ConfirmationMessageType) {
    const { t } = useTranslation();
    const { isLoading } = useSnapshot(store);

    return (
        <React.Fragment>
            <StyledModal toggle={true} isOpen={data.isModalOpened!} centered>
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        if (isLoading === false) {
                            data.setIsModalOpened(!data.isModalOpened);
                        }
                    }}
                >
                    <StyledH2>{t("Alert")}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel
                        className="pl-3 m-0"
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {data.msg}
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0 ">
                    {!data.isArchived && (
                        <CustomSecondaryColorButton
                            className={`${classnames({
                                "not-allowed-icon__clz": isLoading,
                            })}`}
                            outline
                            variant="light"
                            onClick={() => {
                                data.setIsModalOpened(!data.isModalOpened);
                            }}
                            rounded
                        >
                            {t("Cancel")}
                        </CustomSecondaryColorButton>
                    )}
                    <CustomMainColorButton
                        className={`${classnames("mr-2", {
                            "not-allowed-icon__clz": isLoading,
                        })}`}
                        rounded
                        variant="primary"
                        onClick={() => {
                            setIsLoading(true);
                            !data.isArchived
                                ? data.setIsValidateClicked(true)
                                : data.setIsModalOpened(!data.isModalOpened);
                        }}
                    >
                        {!data.isArchived ? t("Validate") : t("Ok")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
            <NProgressWrapper isLoading={isLoading} />{" "}
        </React.Fragment>
    );
}
