import React from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next/";
import Avatar from "react-avatar";
import { cache } from "swr";
import { useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import { Tooltip } from "@mui/material";
import { useSnapshot } from "valtio";

import {
    ColorSvgIcon,
    KeySvgIcon,
    LogOutSvgIcon,
    MagicSvgIcon,
} from "../Common/SvgIcons";
import classnames from "classnames";
import { getAuthorizationStates } from "./StyledSidebarMenu";
import { setMainColor, setSecondaryColor, store } from "@store";

import { ArrowDownIcon } from "@components/Common/SvgIcons/ArrowDownIcon";
import RestoreSvgIcon from "@components/Common/SvgIcons/RestoreSvgIcon";
import {
    store as saleMode,
    setIsEdited as setIsEditedModeOfSale,
    setIsActionsClicked as setIsActionsClickedModeOfSale,
    setActions as setActionsModeOfSale,
} from "@pages/SettingGeneral/ModeOfSale/store";
import {
    store as langue,
    setIsEdited as setIsEditedLanguage,
    setIsActionsClicked as setIsActionsClickedLanguage,
    setActions as setActionsLanguage,
} from "@pages/SettingGeneral/Language/store";
import {
    storeVatRate,
    setIsEdited as setIsEditedVatRate,
    setIsActionsClicked as setIsActionsClickedVatRate,
    setActions as setActionsVatRate,
} from "@pages/SettingGeneral/VatRate/store";
import {
    store as saleSupport,
    setIsEdited as setIsEditedSaleSupport,
    setIsActionsClicked as setIsActionsClickedSaleSupport,
    setActions as setActionsSaleSupport,
} from "@pages/SettingGeneral/SaleSupport/store";
import {
    store as modeOfInformation,
    setIsEdited as setIsEditedModeOfInformation,
    setIsActionsClicked as setIsActionsClickedModeOfInformation,
    setActions as setActionsModeOfInformation,
} from "@pages/SettingGeneral/ModeOfInformation/store";
import {
    store as paymentMethod,
    setIsEdited as setIsEditedPaymentMethod,
    setIsActionsClicked as setIsActionsClickedPaymentMethod,
    setActions as setActionsPaymentMethod,
} from "@pages/SettingGeneral/PaymentMethod/store";
import {
    store as logo,
    setIsEdited as setIsEditedLogo,
    setIsActionsClicked as setIsActionsClickedLogo,
    setActions as setActionsLogo,
} from "@pages/SettingGeneral/Logo/store";
import {
    store as thirdParty,
    setIsEdited as setIsEditedThirdParty,
    setIsActionsClicked as setIsActionsClickedThirdParty,
    setActions as setActionsThirdParty,
} from "@pages/SettingGeneral/ThirdParty/store";
import {
    store as devise,
    setIsEdited as setIsEditedDevise,
    setIsActionsClicked as setIsActionsClickedDevise,
    setActions as setActionsDevise,
} from "@pages/SettingGeneral/Devise/store";
import {
    store as fidelity,
    setIsEdited as setIsEditedFidelity,
    setIsActionsClicked as setIsActionsClickedFidelity,
    setActions as setActionsFidelity,
} from "@pages/SettingGeneral/Fidelity/store";
import {
    store as posEditor,
    setIsEdited as setIsEditedPosEditor,
    setIsActionsClicked as setIsActionsClickedPosEditor,
    setActions as setActionsPosEditor,
} from "@pages/SettingGeneral/PosEditor/store";
import {
    store as projectStore,
    setIsEdited as setIsEditedProject,
    setIsActionsClicked as setIsActionsClickedProject,
    setActions as setActionsProject,
} from "@pages/Project/store";
import {
    store as applicationsStore,
    setIsEdited as setIsEditedApplications,
    setIsActionsClicked as setIsActionsClickedApplications,
    setActions as setActionsApplications,
} from "@pages/Applications/store";
import {
    store as printer,
    setIsEdited as setIsEditedPrinter,
    setIsActionsClicked as setIsActionsClickedPrinter,
    setActions as setActionsPrinter,
} from "@pages/Peripherals/Printers/store";
import {
    store as monetic,
    setIsEdited as setIsEditedMonetic,
    setIsActionsClicked as setIsActionsClickedMonetic,
    setActions as setActionsMonetic,
} from "@pages/Peripherals/Monetics/store";
import {
    store as cashManagement,
    setIsEdited as setIsEditedCashManagement,
    setIsActionsClicked as setIsActionsClickedCashManagement,
    setActions as setActionsCashManagement,
} from "@pages/Peripherals/CashManagement/store";
import "./layout.css";

type ProfileMenuType = {
    logout: Function;
    menu: boolean;
    setMenu: Function;
    name: string;
    email: any;
};

export default function ProfileMenu({
    logout,
    menu,
    setMenu,
    name,
    email,
}: ProfileMenuType) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const sketchPickerRef = React.useRef(null);
    const {
        isEdited: modeOfSaleIsEdited,
        isActionsClicked: modeOfSaleIsActionsClicked,
    } = useSnapshot(saleMode);
    const {
        isEdited: langueIsEdited,
        isActionsClicked: langueIsActionsClicked,
    } = useSnapshot(langue);
    const {
        isEdited: vatRateIsEdited,
        isActionsClicked: vatRateIsActionsClicked,
    } = useSnapshot(storeVatRate);
    const {
        isEdited: saleSupportIsEdited,
        isActionsClicked: saleSupportIsActionsClicked,
    } = useSnapshot(saleSupport);
    const {
        isEdited: modeOfInformationIsEdited,
        isActionsClicked: modeOfInformationIsActionsClicked,
    } = useSnapshot(modeOfInformation);
    const {
        isEdited: paymentMethodIsEdited,
        isActionsClicked: paymentMethodIsActionsClicked,
    } = useSnapshot(paymentMethod);
    const {
        isEdited: logoIsEdited,
        isActionsClicked: logoIsActionsClicked,
    } = useSnapshot(logo);
    const {
        isEdited: thirdPartyIsEdited,
        isActionsClicked: thirdPartyIsActionsClicked,
    } = useSnapshot(thirdParty);
    const {
        isEdited: deviseIsEdited,
        isActionsClicked: deviseIsActionsClicked,
    } = useSnapshot(devise);
    const {
        isEdited: fidelityIsEdited,
        isActionsClicked: fidelityIsActionsClicked,
    } = useSnapshot(fidelity);
    const {
        isEdited: posEditorIsEdited,
        isActionsClicked: posEditorIsActionsClicked,
    } = useSnapshot(posEditor);
    const {
        isEdited: projectIsEdited,
        isActionsClicked: projectIsActionsClicked,
    } = useSnapshot(projectStore);
    const {
        isEdited: applicationsIsEdited,
        isActionsClicked: applicationsIsActionsClicked,
    } = useSnapshot(applicationsStore);
    const {
        isEdited: printerIsEdited,
        isActionsClicked: printerIsActionsClicked,
    } = useSnapshot(printer);
    const {
        isEdited: moneticIsEdited,
        isActionsClicked: moneticIsActionsClicked,
    } = useSnapshot(monetic);
    const {
        isEdited: cashManagementIsEdited,
        isActionsClicked: cashManagementIsActionsClicked,
    } = useSnapshot(cashManagement);

    const { secondaryColor, mainColor } = useSnapshot(store);
    const [colorMainPicker, setColorMainPicker] = React.useState(false);

    const [colorSecondaryPicker, setColorSecondaryMainPicker] = React.useState(
        false
    );

    function changePassword() {
        cache.clear();
        localStorage.clear();
        navigate("/update");
    }

    const handleChangeSecondaryColor = (color: any) => {
        setColorSecondaryMainPicker(!colorSecondaryPicker);
        setColorMainPicker(false);
    };

    const handleChangeMainColor = () => {
        setColorMainPicker(!colorMainPicker);
        setColorSecondaryMainPicker(false);
    };
    const handleResetColor = () => {
        localStorage.setItem("mainColor", "#171616");
        setMainColor("#171616");
        handleRequestColor("#171616", secondaryColor);
    };

    const handleSecondaryResetColor = () => {
        localStorage.setItem("secondaryColor", "#c4bfbe");
        setSecondaryColor("#c4bfbe");
        handleRequestColor(mainColor, "#c4bfbe");
    };

    React.useEffect(() => {
        setMainColor(
            localStorage.getItem("mainColor") === undefined ||
                localStorage.getItem("mainColor") === "null" ||
                localStorage.getItem("mainColor") === ""
                ? "#171616"
                : localStorage.getItem("mainColor")!
        );

        setSecondaryColor(
            localStorage.getItem("secondaryColor") === undefined ||
                localStorage.getItem("secondaryColor") === "null" ||
                localStorage.getItem("secondaryColor") === ""
                ? "#605e5d"
                : localStorage.getItem("secondaryColor")!
        );
    }, []);

    async function handleRequestColor(mColor: string, sColor: string) {
        const urlVerifyUserAccount: string = `${process.env.REACT_APP_ACCESS_API_URL}/userColor`;

        await fetch(urlVerifyUserAccount, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `email=${email}&mainColor=${mColor}&secondaryColor=${sColor}`,
            method: "POST",
        })
            .then((result) => result.json())
            .then(async (response: any) => {
                if (response.error) {
                }
            })
            .catch((err: any) => {
                return false;
            });
    }

    const handleClickOutsideColorPicker = async (event: any) => {
        if (
            sketchPickerRef.current &&
            !(sketchPickerRef.current as any).contains(event.target)
        ) {
            handleRequestColor(mainColor, secondaryColor);
        }
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideColorPicker);
        return () => {
            document.removeEventListener(
                "mousedown",
                handleClickOutsideColorPicker
            );
        };
        // eslint-disable-next-line
    }, [secondaryColor, mainColor]);
    const isModifieApparence: boolean = getAuthorizationStates(
        "a60a2f53-9512-2567-a0e5-9fda2fce797a"
    ).Modifie;

    function handleOnChangeEvent() {
        if (modeOfSaleIsEdited === true) {
            setIsActionsClickedModeOfSale(!modeOfSaleIsActionsClicked);
            setActionsModeOfSale(() => {
                setIsEditedModeOfSale(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (langueIsEdited === true) {
            setIsActionsClickedLanguage(!langueIsActionsClicked);
            setActionsLanguage(() => {
                setIsEditedLanguage(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (vatRateIsEdited === true) {
            setIsActionsClickedVatRate(!vatRateIsActionsClicked);
            setActionsVatRate(() => {
                setIsEditedVatRate(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (saleSupportIsEdited === true) {
            setIsActionsClickedSaleSupport(!saleSupportIsActionsClicked);
            setActionsSaleSupport(() => {
                setIsEditedSaleSupport(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (modeOfInformationIsEdited === true) {
            setIsActionsClickedModeOfInformation(
                !modeOfInformationIsActionsClicked
            );
            setActionsModeOfInformation(() => {
                setIsEditedModeOfInformation(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (paymentMethodIsEdited === true) {
            setIsActionsClickedPaymentMethod(!paymentMethodIsActionsClicked);
            setActionsPaymentMethod(() => {
                setIsEditedPaymentMethod(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (logoIsEdited === true) {
            setIsActionsClickedLogo(!logoIsActionsClicked);
            setActionsLogo(() => {
                setIsEditedLogo(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (thirdPartyIsEdited === true) {
            setIsActionsClickedThirdParty(!thirdPartyIsActionsClicked);
            setActionsThirdParty(() => {
                setIsEditedThirdParty(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (deviseIsEdited === true) {
            setIsActionsClickedDevise(!deviseIsActionsClicked);
            setActionsDevise(() => {
                setIsEditedDevise(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (fidelityIsEdited === true) {
            setIsActionsClickedFidelity(!fidelityIsActionsClicked);
            setActionsFidelity(() => {
                setIsEditedFidelity(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (posEditorIsEdited === true) {
            setIsActionsClickedPosEditor(!posEditorIsActionsClicked);
            setActionsPosEditor(() => {
                setIsEditedPosEditor(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (projectIsEdited === true) {
            setIsActionsClickedProject(!projectIsActionsClicked);
            setActionsProject(() => {
                setIsEditedProject(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (applicationsIsEdited === true) {
            setIsActionsClickedApplications(!applicationsIsActionsClicked);
            setActionsApplications(() => {
                setIsEditedApplications(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (printerIsEdited === true) {
            setIsActionsClickedPrinter(!printerIsActionsClicked);
            setActionsPrinter(() => {
                setIsEditedPrinter(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (moneticIsEdited === true) {
            setIsActionsClickedMonetic(!moneticIsActionsClicked);
            setActionsMonetic(() => {
                setIsEditedMonetic(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else if (cashManagementIsEdited === true) {
            setIsActionsClickedCashManagement(!cashManagementIsActionsClicked);
            setActionsCashManagement(() => {
                setIsEditedCashManagement(false);
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            });
        } else {
            setColorMainPicker(false);
            setMenu(!menu);
            setColorSecondaryMainPicker(false);
        }
    }

    return (
        <Dropdown
            isOpen={menu}
            className="d-inline-block"
            autoClose="outside"
            toggle={() => {
                handleOnChangeEvent();
            }}
        >
            <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button"
            >
                <Avatar className="mr-0" name={name} round size="40" />
                <ArrowDownIcon fill="black" className="pt-1" />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem>
                    <div
                        style={{
                            width: "300px",
                            overflow: "hidden",
                        }}
                        className="d-flex justify-content-start"
                    >
                        <Avatar
                            className="mt-2 mb-2"
                            name={name}
                            round
                            size="70"
                            maxInitials={2}
                            color="#2196df"
                        />
                        <div className="pt-4 pl-3">
                            <div className="line1__clz">{name}</div>
                            <div className="line2__clz">{email}</div>
                        </div>
                    </div>
                </DropdownItem>
                <hr style={{ color: "#000000" }} />
                <DropdownItem tag="a" onClick={() => changePassword()}>
                    <KeySvgIcon height="20" width="20" />
                    <i className="bx bx-lock-open font-size-16 align-middle mr-1 ml-3"></i>
                    {t("Change password")}
                </DropdownItem>
                <DropdownItem
                    className={`${classnames("dropdown-item pl-4", {
                        "d-none": isModifieApparence === false,
                    })}`}
                    style={{ pointerEvents: "none" }}
                >
                    <MagicSvgIcon height="25" width="25" fill="black" />
                    <i className="bx bx-power-off font-size-16 align-middle text-danger ml-3"></i>

                    <span>{t("Appearance")}</span>
                </DropdownItem>
                <DropdownItem
                    className={`${classnames("dropdown-item pl-5 ", {
                        "d-none": isModifieApparence === false,
                    })}`}
                    toggle={false}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{ pointerEvents: "none", cursor: "default" }}
                            className="ml-3 mr-3"
                        >
                            {t("Main color")}
                        </span>
                        <div
                            style={{
                                borderRadius: 3,
                                width: 60,
                                height: 20,
                                backgroundColor: mainColor,
                                marginRight: 10,
                            }}
                        ></div>

                        <Tooltip title={t("Pick main color")}>
                            <span
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <ColorSvgIcon
                                    onClick={handleChangeMainColor}
                                    height="20"
                                    width="20"
                                    color={mainColor}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title={t("Restore main color")}>
                            <span onClick={handleResetColor} className="pl-3">
                                <RestoreSvgIcon
                                    height="20"
                                    width="20"
                                    stroke={"#171616"}
                                />
                            </span>
                        </Tooltip>
                        {colorMainPicker ? (
                            <div
                                style={{
                                    position: "fixed",
                                    top: "85%",
                                    right: "14%",
                                }}
                                ref={sketchPickerRef}
                            >
                                <SketchPicker
                                    color={mainColor}
                                    onChange={(color) => {
                                        localStorage.setItem(
                                            "mainColor",
                                            color.hex
                                        );
                                        setMainColor(color.hex);
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                </DropdownItem>
                {false && (
                    <DropdownItem
                        toggle={false}
                        className={`${classnames(" dropdown-item pl-5", {
                            "d-none": isModifieApparence === false,
                        })}`}
                        onBlur={() => setColorSecondaryMainPicker(false)}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span className="ml-3 mr-2">
                                {t("Secondary color")}
                            </span>
                            <div
                                style={{
                                    borderRadius: 3,
                                    width: 60,
                                    height: 20,
                                    backgroundColor: secondaryColor,
                                    marginRight: 10,
                                }}
                            ></div>
                            <Tooltip title={t("Pick secondary color")}>
                                <span onClick={handleChangeSecondaryColor}>
                                    <ColorSvgIcon
                                        height="20"
                                        width="20"
                                        color={secondaryColor}
                                    />
                                </span>
                            </Tooltip>

                            <Tooltip title={t("Restore secondary color")}>
                                <span
                                    onClick={handleSecondaryResetColor}
                                    className="pl-3"
                                >
                                    <RestoreSvgIcon
                                        height="20"
                                        width="20"
                                        stroke={"#c4bfbe"}
                                    />
                                </span>
                            </Tooltip>
                            {colorSecondaryPicker ? (
                                <div
                                    ref={sketchPickerRef}
                                    style={{
                                        position: "fixed",
                                        top: "86%",
                                        right: "14%",
                                    }}
                                >
                                    <SketchPicker
                                        color={secondaryColor}
                                        onChange={(color) => {
                                            localStorage.setItem(
                                                "secondaryColor",
                                                color.hex
                                            );
                                            setSecondaryColor(color.hex);
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </DropdownItem>
                )}
                <DropdownItem
                    style={{ cursor: "pointer" }}
                    onClick={() => logout()}
                    className="dropdown-item"
                >
                    <LogOutSvgIcon height="25" width="25" fill="red" />
                    <i className="bx bx-power-off font-size-16 align-middle text-danger ml-3"></i>
                    <span>{t("Logout")}</span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
