import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { uuid } from "uuidv4";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import store, {
    setIsHistoryModalOpened,
    setSelectedThirdParties,
    setIsInputUpdated,
    setSelectedHistoryThirdParties,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setKey,
} from "../store";
import { store as useStore } from "../../../../components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import NProgressWrapper from "@components/Common/NProgressWrapper";
import { CreateDataThirdParty } from "../helper";
import { CustomIconsType } from "../Types";
import IsModify from "./IsModify";

export function CustomIcons({ data }: CustomIconsType) {
    const { t } = useTranslation();
    const {
        isInputUpdated,
        isHistoryModalOpened,
        shopsThirdPartyData,
        thirdPartyToBeArchived,
        isActionsClicked,
        isEdited,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { oneShop, operatorID, userID, franchiseID } = useSnapshot(useStore);
    const [thirdPartyState, setThirdPartyState] = React.useState<boolean>(
        data.selectedRowData.selectedRow.isActive
    );
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );

    const uuidUser = localStorage.getItem("uuidUser");
    let dataArchive: any[] = [];
    if (!oneShop) {
        dataArchive = ArchivedThirdParty();
    } else {
        dataArchive = ArchivedArchivedThirdPartyFranchise();
    }
    function ArchivedArchivedThirdPartyFranchise() {
        let idsAssociated: any[] = [];
        if (shopsThirdPartyData !== undefined) {
            shopsThirdPartyData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchivedThirdParty() {
        let idsAssociated: any[] = [];
        if (shopsThirdPartyData !== undefined) {
            Object.values(shopsThirdPartyData).forEach((element: any) => {
                element?.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function handleArchiveThirdParty(element: any) {
        let el = JSON.parse(JSON.stringify(element));
        data.setLimit(1);
        setIsLoading(true);
        let thirdData = CreateDataThirdParty(
            oneShop,
            false,
            el.shopId,
            false,
            el.isFavorite,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            el.Id,
            el[t("Third party")],
            el.ip,
            el.portApi,
            el.salesId,
            el.menusId,
            el.baseUrl,
            el[t("Remark")],
            true,
            el.apiKey
        );
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(!oneShop ? [thirdData] : thirdData),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t(
                            "The third party has been successfully archived."
                        )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsLoading(false);
                    setKey(uuid());
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    React.useEffect(() => {
        if (isValidateClicked === true) {
            handleArchiveThirdParty(thirdPartyToBeArchived);
        }
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-around">
                {modify === false || data.isArchivedThirdPartyClicked ? (
                    <Tooltip title={t("To consult")}>
                        <div>
                            {" "}
                            <ConsultSvgIcon
                                className="pointer__clz afa_icn_toConsult"
                                height={35}
                                width={35}
                                style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    bottom: "5px",
                                }}
                                fill="black"
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            data.setThirdParty(
                                                data.selectedRowData.selectedRow
                                            );
                                            data.setIsAddThirdPartyClicked(
                                                true
                                            );
                                            setIsInputUpdated(!isInputUpdated);
                                        });
                                    } else {
                                        data.setThirdParty(
                                            data.selectedRowData.selectedRow
                                        );
                                        data.setIsAddThirdPartyClicked(true);
                                        setIsInputUpdated(!isInputUpdated);
                                    }
                                    data.setIsConsult(true);
                                }}
                            />
                        </div>
                    </Tooltip>
                ) : null}
                {modify === true ? (
                    <IsModify
                        data={{
                            ...data,
                            thirdPartyState,
                            setThirdPartyState,
                            dataArchive,
                            setIsValidateClicked,
                        }}
                    />
                ) : null}
                <Tooltip title={t("Consult history")}>
                    <div>
                        <TimePastSvgIcon
                            onClick={(e: any) => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setSelectedThirdParties([
                                            {
                                                [data.selectedRowData
                                                    .selectedRow.shopId]:
                                                    data.selectedRowData
                                                        .selectedRow.Id,
                                            },
                                        ]);
                                        setSelectedHistoryThirdParties(
                                            data.selectedRowData.selectedRow
                                        );
                                        data.setIsAddThirdPartyClicked(false);
                                    });
                                } else {
                                    data.setIsAddThirdPartyClicked(false);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setSelectedThirdParties([
                                        {
                                            [data.selectedRowData.selectedRow
                                                .shopId]:
                                                data.selectedRowData.selectedRow
                                                    .Id,
                                        },
                                    ]);
                                    setSelectedHistoryThirdParties(
                                        data.selectedRowData.selectedRow
                                    );
                                }
                            }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_FRhistory",

                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                        />
                    </div>
                </Tooltip>
            </div>

            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}
