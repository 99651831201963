import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { setShopDataIsUpdated } from "../store";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { ConfirmationModificationMessageType } from "../Types";
import { CreateDataThirdParty } from "../helper";

export function ConfirmationModificationModal({
    data,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, oneShop, operatorID } = useSnapshot(store);
    async function editThirdParty() {
        toast.dismiss();
        let localData: any = [];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        data.data.forEach(async (el: any) => {
            let thirdData: any = CreateDataThirdParty(
                oneShop,
                false,
                el.shopId,
                el.isActive,
                el.isFavorite,
                userID,
                franchiseID,
                operatorID,
                userID,
                el.Id,
                el[t("Third party")],
                el.ip,
                el.portApi,
                el.salesId,
                el.menusId,
                el.baseUrl,
                data.remark,
                false,
                el.apiKey
            );
            localData.push(thirdData);
        });

        try {
            await fetch(apiUrlAdd, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(localData),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t(
                            "The Store Third Party has been successfully modified."
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    data.setIsAddThirdPartyClicked(false);
                    data.setIsEdited(false);
                    data.setIsModalOpened(!data.isModalOpened);
                    data.setThirdPartyData([]);
                    data.resetData();
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    return (
        <StyledModal toggle={true} isOpen={data.isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => data.setIsModalOpened(!data.isModalOpened)}
            >
                <StyledH2>{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        `Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.`
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        data.setIsModalOpened(!data.isModalOpened);
                        data.setIsAddThirdPartyClicked(false);
                        data.setIsEdited(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        editThirdParty();
                        data.setIsModalOpened(!data.isModalOpened);
                        setShopDataIsUpdated(true);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
