import React from "react";
import { StyledLabel } from "@aureskonnect/react-ui";
import { AvGroup, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";

import { setIsEdited } from "../store";

import { KoustConfigType } from "../Types";

export default function KoustConfig({ data }: KoustConfigType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <AvGroup className={`${data.isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("API key")}
                </StyledLabel>
                <AvField
                    autocomplete="off"
                    id="apiKey"
                    name="apiKey"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        data.setInputApiKey(false);
                        data.setApiKey(e.target.value);
                        setIsEdited(true);
                    }}
                    value={data.apiKey}
                />
                {data.inputApiKey ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter an API key")}
                    </div>
                ) : null}
            </AvGroup>
        </React.Fragment>
    );
}
