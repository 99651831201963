import React from "react";
import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { AvGroup, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { setIsEdited } from "../store";

import { httpRegex, ipMatchWord, numberMatchWord } from "@constants/index";
import { AdoriaConfigType } from "../Types";

export default function AdoriaConfig({ data }: AdoriaConfigType) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <AvGroup className={`${data.isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("IP address")}
                </StyledLabel>
                <AvField
                    className={classnames("afp_inp_ipAdresse", {
                        input__clz: data.inputIp || data.emptyIp,
                    })}
                    autocomplete="off"
                    id="ipAddress"
                    name="ipAddress"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        if (!e.target.value.match(ipMatchWord)) {
                            data.setInputIp(true);
                        } else {
                            data.setInputIp(false);
                        }
                        data.setEmptyIp(false);
                        data.setIp(e.target.value);
                        setIsEdited(true);
                    }}
                    value={data.ip}
                />
                {data.inputIp || data.emptyIp ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t(
                            data.emptyIp
                                ? "Please enter a IP address"
                                : data.inputIp
                                ? "IP address is invalid"
                                : ""
                        )}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup className={`${data.isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Port API")}
                </StyledLabel>
                <AvField
                    className={classnames("afp_inp_portNumber", {
                        input__clz: data.inputPort,
                    })}
                    autocomplete="off"
                    id="port"
                    name="port"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        data.setPort(e.target.value);
                        data.setInputPort(false);
                        setIsEdited(true);
                    }}
                    value={data.port}
                    validate={{
                        pattern: {
                            value: numberMatchWord,
                            errorMessage: t("Port number is invalid"),
                        },
                    }}
                />
                {data.inputPort && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a port API")}
                    </div>
                )}
            </AvGroup>
            <AvGroup className={`${data.isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel
                    htmlFor="example-input"
                    className="required__clz mt-3"
                >
                    {t("Sales Id")}
                </StyledLabel>
                <AvField
                    className={classnames({
                        input__clz: data.inputSalesId,
                        readOnly__clz: data.isConsult,
                    })}
                    autocomplete="off"
                    id="data."
                    name="salesId"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        data.setSalesId(e.target.value);
                        data.setInputSalesId(false);
                        setIsEdited(true);
                    }}
                    value={data.salesId}
                />
                {data.inputSalesId ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a sales Id")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup className={`${data.isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel
                    htmlFor="example-input"
                    className="required__clz mt-3"
                >
                    {t("Menus Id")}
                </StyledLabel>
                <AvField
                    className={classnames({
                        input__clz: data.inputMenusId,
                        readOnly__clz: data.isConsult,
                    })}
                    autocomplete="off"
                    id="menusId"
                    name="menusId"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        data.setMenusId(e.target.value);
                        data.setInputMenusId(false);
                        setIsEdited(true);
                    }}
                    value={data.menusId}
                />
                {data.inputMenusId ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a menus Id")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup className={`${data.isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel
                    htmlFor="example-input"
                    className="required__clz mt-3"
                >
                    {t("Base URL")}
                </StyledLabel>
                <StyledTextInput
                    className={classnames("mnt_inp_baseUrlServerURL", {
                        input__clz: data.inputBaseUrl || data.isInvalidBaseUrl,
                        readOnly__clz: data.isConsult,
                    })}
                    autocomplete="off"
                    id="baseUrl"
                    name="baseUrl"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        if (
                            !e.target.value.match(httpRegex) &&
                            e.target.value !== ""
                        ) {
                            data.setIsInvalidBaseUrl(true);
                        } else {
                            data.setBaseUrl(e.target.value.trim());
                            data.setIsInvalidBaseUrl(false);
                        }
                        data.setInputBaseUrl(false);
                        setIsEdited(true);
                    }}
                    value={data.baseUrl}
                />
                {data.inputBaseUrl ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a baseUrl")}{" "}
                    </div>
                ) : null}
                {data.isInvalidBaseUrl ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Base Url is invalid")}
                    </div>
                ) : null}
            </AvGroup>
        </React.Fragment>
    );
}
