import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import ErrorToast from "@components/Common/ErrorTost";

import store, {
    setIsLoading,
    setKey,
    setFnDelete,
    setIsDeleteCheckClick,
} from "../store";

import { store as ModeOfInformation } from "../../../../components/VerticalLayout/store";
import { generalConfigStore, setItemsDisable } from "@store";

export function ActionColumnModeInformation({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    isModeOfInformationArchived,
    setIsModeOfInformationArchived,
    selectedIds,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        archivedModeInformationFranchise,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);

    const [error, setError] = React.useState(false);

    const { franchiseID, userID, operatorID } = useSnapshot(ModeOfInformation);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let existArrayOfRows: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (
                existArrayOfRows.indexOf(`${el.Id}${el.shopId}`) === -1 &&
                el[t("Shop")] === undefined
            ) {
                existArrayOfRows.push(`${el.Id}${el.shopId}`);
                arrayOfRows.push(el);
            }
        });

        return arrayOfRows;
    };

    function ArchiveModeOfInformationFranchise() {
        let idsAssociated: any = [];

        if (archivedModeInformationFranchise !== undefined) {
            archivedModeInformationFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }

        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }
    async function archiveModeOfInformation() {
        setLimit(3);
        setIsLoading(true);
        toast.dismiss();
        let arrayOfRows = getArrayOfRows();
        let localData: any = [];
        let isSomeConsumed = false;
        for (let el of arrayOfRows) {
            if (el.Id !== undefined) {
                let isConsumed: any = await isInformationModeConsumed(el);
                if (isConsumed === true) {
                    isSomeConsumed = true;
                } else {
                    let dataModeOfInformation = {
                        userId: userID,
                        operatorId: operatorID,
                        shopId: [el.shopId],
                        franchiseId: franchiseID,
                        uuidUser: uuidUser,
                        isShop: true,
                        data: {
                            [el.Id]: {
                                designation: el[t("Mode of information")],
                                idMI: el?.idMI,
                                supportOfSale: el[t("Sales support")],
                                salesSupportData: [el[t("Sales support")]],
                                displayName: el[t("Display name")],
                                modeOfSale: el[t("Sales method")],
                                tag: el.tag,
                                isArchived: true,
                                state: false,
                                shopId: el.shopId,
                                stateShop: el.state,
                                advancedDisplayName: el.advancedDisplayName,
                            },
                        },
                    };
                    localData.push(dataModeOfInformation);
                }
            }

            const item = itemsDisable.find(
                (item: any) => item.id === el.Id && item.shopId === el.shopId
            );
            if (item !== undefined) {
                const item = itemsDisable.filter(
                    (item: any) =>
                        !(item.id === el.Id && item.shopId === el.shopId)
                );
                setItemsDisable(item);
                localStorage.setItem("itemsDisable", JSON.stringify(item));
            }
        }
        if (localData.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                    await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(localData),
                        }
                    ).then((response) => response.json())
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setError(true);
                setIsLoading(false);
            }

            if (error === false) {
                toast.success(
                    `${t(
                        "The Information mode has been successfully archived"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsLoading(false);
                setKey(uuid());
            }
        }
        if (isSomeConsumed) {
            ErrorToast(
                t(
                    "Please note, you cannot archive an element used in a template"
                )
            );
            setIsLoading(false);
        }
    }

    async function activeModeOfInformation() {
        setIsLoading(true);
        toast.dismiss();
        let arrayOfRows = getArrayOfRows();
        let localData: any = [];

        arrayOfRows.forEach(async (el: any) => {
            if (el.Id !== undefined) {
                let dataModeOfInformation = {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    isShop: true,
                    data: {
                        [el.Id]: {
                            designation: el[t("Mode of information")],
                            idMI: el?.idMI,
                            supportOfSale: el[t("Sales support")],
                            salesSupportData: [el[t("Sales support")]],
                            displayName: el[t("Display name")],
                            modeOfSale: el[t("Sales method")],
                            tag: el.tag,
                            isArchived: false,
                            state: el.state,
                            shopId: el.shopId,
                            stateShop: el.state,
                            advancedDisplayName: el.advancedDisplayName,
                        },
                    },
                };

                localData.push(dataModeOfInformation);
            }

            if (
                el.state === false &&
                itemsDisable.find(
                    (item: any) =>
                        item.id === el.Id && item.shopId === el.shopId
                ) === undefined
            ) {
                const item = [
                    ...JSON.parse(JSON.stringify(itemsDisable)),
                    {
                        id: el.Id,
                        state: false,
                        shopId: el.shopId,
                    },
                ];
                setItemsDisable(item);
                localStorage.setItem("itemsDisable", JSON.stringify(item));
            }
        });
        if (localData.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                    await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(localData),
                        }
                    ).then((response) => response.json())
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setError(true);
                setIsLoading(false);
            }

            if (error === false) {
                toast.success(
                    `${t(
                        "Information mode has been successfully unarchived"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsLoading(false);
                setKey(uuid());
            }
        }
    }
    async function isModeOfSaleArchived() {
        toast.dismiss();
        let isModeOfSaleArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale_archived?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRows.forEach((element: any) => {
                            element[t("Sales method")].forEach((sale: any) => {
                                if (
                                    elt.subRows.some(
                                        (saleMethod: any) =>
                                            `${saleMethod["Mode of sale"]} ${saleMethod.shopId}` ===
                                            `${sale.label} ${element.shopId}`
                                    )
                                ) {
                                    isModeOfSaleArchived = true;
                                }
                            });
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        );
        return isModeOfSaleArchived;
    }
    async function isInformationModeConsumed(element: any) {
        toast.dismiss();
        let isInformationModeUsed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${element.shopId}&franchiseId=${franchiseID}&type=informationModes`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (
                        result.data.some(
                            (el: any) =>
                                el.id === element.idMI &&
                                element.Sales_support.value ===
                                    "3ef7df0f-6a8e-4470-8851-8978e0ba71c2"
                        )
                    ) {
                        isInformationModeUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isInformationModeUsed;
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown cmn_drp_BTmenu">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsModeOfInformationArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistActivated">
                                {t("Activated mode of information")}(s) (
                                {numberActiveMultiShop
                                    ? numberActiveMultiShop
                                    : 0}
                                )
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsModeOfInformationArchived(true);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistArchived">
                                {t("Archived mode of information")}(s) (
                                {numberArchiveMultiShop
                                    ? numberArchiveMultiShop
                                    : 0}
                                )
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz cmn_btn_BThistory",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (selectedIds.length <= 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }
                            }}
                        >
                            <span className="ml-2">{t("Historical")}</span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz cmn_btn_BTarchived cmn_btn_BTunarchive",
                                        {
                                            "not-allowed-icon__clz":
                                                isLoading ||
                                                selectedIds.length === 0 ||
                                                (isModeOfInformationArchived &&
                                                    getArrayOfRows().some(
                                                        (element: any) =>
                                                            ArchiveModeOfInformationFranchise().includes(
                                                                element.Id
                                                            )
                                                    )),
                                        }
                                    )}`}
                                    onClick={async () => {
                                        setIsLoading(true);
                                        let isArchived = await isModeOfSaleArchived();
                                        if (selectedIds.length <= 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                            setIsLoading(false);
                                        } else if (
                                            isArchived === true &&
                                            isModeOfInformationArchived
                                        ) {
                                            ErrorToast(
                                                `${t(
                                                    "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                )!}`
                                            );
                                            setIsLoading(false);
                                        } else {
                                            if (
                                                isModeOfInformationArchived ===
                                                true
                                            ) {
                                                activeModeOfInformation();
                                            } else {
                                                setIsLoading(false);
                                                setIsDeleteCheckClick(true);
                                                setFnDelete(async () => {
                                                    archiveModeOfInformation();
                                                });
                                            }
                                        }
                                    }}
                                >
                                    <span className="ml-2">
                                        {isModeOfInformationArchived
                                            ? t("Unarchive")
                                            : t("Archiver")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
