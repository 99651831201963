import React from "react";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel, StyledModal } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import {
    store,
    setPrinterConfigModalOpened,
    setClickValidatePrinter,
    setIsValidateClicked,
    setIsValidateButtonEnabled,
} from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { mutate } from "swr";
import {
    setDataIsUpdated,
    setIsSettingFromPrinter,
    setIsSettingModalOpened,
} from "@pages/Peripherals/Printers/store";
import { setIsConfigurationPrinterClicked } from "@pages/Applications/Kiosk/store";
import { setGlobalDataIsUpdated } from "@pages/Applications/store";

import "./index.css";

export function PrinterConfigModal(): JSX.Element {
    const { t } = useTranslation();

    const { printerConfigModalOpened, printerConfigModalData } = useSnapshot(
        store
    );

    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        let ids = [printerConfigModalData.savedData.shopId];
        ids.forEach(async (shop: any) => {
            try {
                mutate(
                    redisApiUrl,
                    await fetch(redisApiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: shop,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        });
    }

    async function editPrinterConfig() {
        try {
            mutate(
                printerConfigModalData.apiUrl,
                await fetch(printerConfigModalData.apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(printerConfigModalData.savedData),
                    method: "PUT",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        setIsSettingModalOpened(false);
                        setClickValidatePrinter(true);
                        setIsSettingFromPrinter(false);
                        toast.success(`${t("Registration successfully")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setTimeout(RedisPublishPeripheral, 3000);
                        setIsValidateClicked(true);
                        setDataIsUpdated(true);
                        setIsConfigurationPrinterClicked(false);
                        setGlobalDataIsUpdated(true);
                        setPrinterConfigModalOpened(false);
                        setIsValidateButtonEnabled(false);
                    })
            );
        } catch (e: any) {
            setIsSettingFromPrinter(false);
            setPrinterConfigModalOpened(false);
            setIsValidateButtonEnabled(false);
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal
            toggle={setPrinterConfigModalOpened}
            isOpen={printerConfigModalOpened}
            centered
            className="modal-dialog-printer__clz"
            backdrop="static"
        >
            <ModalHeader
                toggle={() => {
                    setPrinterConfigModalOpened(false);
                    setIsValidateButtonEnabled(false);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="d-flex flex-column m-0">
                    <span>
                        {t(
                            "Do you want to apply these modifications to all applications that are used immediately?"
                        )}
                    </span>
                    <span>
                        {t(
                            "Attention! If you click on “Validate”, the specific printing setting of the applications will be modified."
                        )}
                    </span>
                </StyledLabel>
            </ModalBody>
            <ModalFooter>
                <CustomSecondaryColorButton
                    outline
                    rounded
                    className="mr-2 cmn_btn_cancelAlert"
                    variant="light"
                    onClick={() => {
                        setPrinterConfigModalOpened(false);
                        setIsValidateButtonEnabled(false);
                    }}
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    variant="primary"
                    className="cmn_btn_validateAlert"
                    onClick={() => {
                        editPrinterConfig();
                    }}
                    rounded={true}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
