import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    SettingsIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import { uuid } from "uuidv4";

import { store as thirdParty } from "../../../../components/VerticalLayout/store";
import store, {
    setFranchiseDataIsUpdated,
    setIsHistoryShopsModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setThirdPartyToBeArchived,
    setSelectedHistoryThirdParties,
    setSelectedThirdParties,
    setShopDataIsUpdated,
    setIsLoading,
    setKey,
} from "../store";
import { setIsDataUpdated } from "@pages/Applications/Kiosk/store";
import {
    setActivationDeactivationMessageModalOpened,
    setMessage,
    setYesFunction,
} from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import ErrorToast from "@components/Common/ErrorTost";
import NProgressWrapper from "@components/Common/NProgressWrapper";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { CreateDataThirdParty } from "../helper";
import { CustomIconsShopType } from "../Types";

export function CustomIcons({ props }: CustomIconsShopType) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        thirdPartyData,
        thirdPartyToBeArchived,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID, oneShop } = useSnapshot(
        thirdParty
    );
    const [thirdPartyState, setThirdPartyState] = React.useState<boolean>(
        props.selectedRowData.selectedRow?.isActive
            ? props.selectedRowData.selectedRow?.isActive
            : false
    );
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [isArchiveSVModalOpened, setIsArchiveSVModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");
    async function handleStateChange(data: boolean) {
        props.setLimit(1);
        setIsLoading(true);
        let id = props.selectedRowData?.selectedRow.Id;
        let thirdData = CreateDataThirdParty(
            oneShop,
            false,
            props.selectedRowData?.selectedRow.shopId,
            data,
            props.selectedRowData?.selectedRow.isFavorite,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            id,
            props.selectedRowData?.selectedRow[t("Third party")],
            props.selectedRowData?.selectedRow.ip,
            props.selectedRowData?.selectedRow.portApi,
            props.selectedRowData?.selectedRow.salesId,
            props.selectedRowData?.selectedRow.menusId,
            props.selectedRowData?.selectedRow.baseUrl,
            props.selectedRowData?.selectedRow[t("Remark")],
            props.selectedRowData?.selectedRow.isArchived,
            props.selectedRowData?.selectedRow.apiKey
        );
        let obj = [thirdData];

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    } else {
                        toast.success(
                            data === true
                                ? `${t(
                                      "The third party has been successfully activated"
                                  )}`
                                : `${t(
                                      "The third party has been successfully deactivated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setKey(uuid());
                        setIsLoading(false);
                    }
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    async function handleActiveThirdParty(selectedRowData: any) {
        setIsLoading(true);
        toast.dismiss();
        props.setLimit(1);
        let id = selectedRowData?.selectedRow.Id;
        let thirdData = CreateDataThirdParty(
            oneShop,
            false,
            selectedRowData?.selectedRow.shopId,
            selectedRowData.selectedRow.isActive,
            selectedRowData?.selectedRow.isFavorite,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            id,
            selectedRowData?.selectedRow[t("Third party")],
            selectedRowData?.selectedRow.ip,
            selectedRowData?.selectedRow.portApi,
            selectedRowData?.selectedRow.salesId,
            selectedRowData?.selectedRow.menusId,
            selectedRowData?.selectedRow.baseUrl,
            selectedRowData?.selectedRow[t("Remark")],
            false,
            selectedRowData?.selectedRow.apiKey
        );
        let dataService = [{ ...thirdData, isShop: true }];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataService),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The third party has been successfully unarchived."
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setKey(uuid());
                        setIsLoading(false);
                        setIsDataUpdated!(true);
                        setShopDataIsUpdated!(true);
                        setFranchiseDataIsUpdated!(true);
                        setNumberActiveMultiShop(numberActiveMultiShop + 1);
                        setNumberArchiveMultiShop(numberArchiveMultiShop - 1);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }
    async function handleArchiveThirdParty() {
        props.setLimit(1);
        setIsArchiveSVModalOpened(true);
    }
    async function handleArchiveThirdPartyShop(selectedRowData: any) {
        setIsLoading(true);
        props.setLimit(1);
        let id = selectedRowData?.Id;
        let thirdData = CreateDataThirdParty(
            oneShop,
            false,
            selectedRowData.shopId,
            false,
            selectedRowData.isFavorite,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            id,
            selectedRowData[t("Third party")],
            selectedRowData.ip,
            selectedRowData.portApi,
            selectedRowData.salesId,
            selectedRowData.menusId,
            selectedRowData.baseUrl,
            selectedRowData[t("Remark")],
            true,
            selectedRowData.apiKey
        );
        let dataConfig = [thirdData];
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t(
                            "The third party has been successfully archived."
                        )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setKey(uuid());
                    setIsLoading(false);
                    setIsDataUpdated!(true);
                    setShopDataIsUpdated!(true);
                    setFranchiseDataIsUpdated!(true);
                    setNumberActiveMultiShop(numberActiveMultiShop - 1);
                    setNumberArchiveMultiShop(numberArchiveMultiShop + 1);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    React.useEffect(() => {
        if (isValidateClicked === true) {
            handleArchiveThirdPartyShop(thirdPartyToBeArchived);
        }
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            {props.selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    props.isArchivedThirdPartyClicked
                                        ? ""
                                        : thirdPartyState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={thirdPartyState}
                                        onChange={async (e: boolean) => {
                                            if (
                                                props.selectedRowData
                                                    .selectedRow.isFavorite &&
                                                props.selectedRowData
                                                    .selectedRow.isActive
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Please note, you cannot deactivate a favorite third party"
                                                    )
                                                );
                                            } else {
                                                setMessage(
                                                    t(
                                                        thirdPartyState
                                                            ? "Caution, the Third party may be used by other modules.\n Would you like to confirm the deactivation of the selected Third party?"
                                                            : "Would you like to confirm the activation of the selected Third party?"
                                                    )
                                                );
                                                setYesFunction(() => {
                                                    setThirdPartyState(
                                                        !thirdPartyState
                                                    );
                                                    handleStateChange(e);
                                                });
                                                setActivationDeactivationMessageModalOpened(
                                                    true
                                                );
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-1 cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz":
                                                    props.isArchivedThirdPartyClicked ||
                                                    isLoading,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!props.isArchivedThirdPartyClicked ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (
                                                    props.selectedRowData
                                                        .selectedRow
                                                        ?.isFavorite === true
                                                ) {
                                                    setIsLoading(false);
                                                    ErrorToast(
                                                        t(
                                                            `Please note, you cannot archive a favorite third party`
                                                        )
                                                    );
                                                } else {
                                                    setThirdPartyToBeArchived(
                                                        props.selectedRowData
                                                            .selectedRow
                                                    );
                                                    handleArchiveThirdParty();
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "ml-1 cmn_icn_BTarchived",
                                                {
                                                    "not-allowed-icon__clz": isLoading,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        thirdPartyData.find(
                                            (el: any) =>
                                                el.Id ===
                                                props.selectedRowData
                                                    .selectedRow.Id
                                        ) !== undefined
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                handleActiveThirdParty(
                                                    props.selectedRowData
                                                );
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-1 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isLoading ||
                                                        thirdPartyData.find(
                                                            (el: any) =>
                                                                el.Id ===
                                                                props
                                                                    .selectedRowData
                                                                    .selectedRow
                                                                    .Id
                                                        ) !== undefined,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            <Tooltip
                                title={
                                    props.isArchivedThirdPartyClicked
                                        ? ""
                                        : t("configure")
                                }
                            >
                                <div>
                                    <SettingsIcon
                                        onClick={() => {
                                            props.setSelectedRows([
                                                props.selectedRowData
                                                    .selectedRow,
                                            ]);
                                            props.setIsConfigureModalOpened(
                                                true
                                            );
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ marginLeft: "1px" }}
                                        className={`${classnames(
                                            "pointer__clz fed_icn_BTconsult",
                                            {
                                                "not-allowed-icon__clz":
                                                    props.isArchivedThirdPartyClicked,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-1 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryShopsModalOpened(
                                        !isHistoryShopsModalOpened
                                    );
                                    setSelectedHistoryThirdParties(
                                        props.selectedRowData.selectedRow
                                    );
                                    setSelectedThirdParties([
                                        {
                                            [props.selectedRowData.selectedRow
                                                .shopId]:
                                                props.selectedRowData
                                                    .selectedRow.Id,
                                        },
                                    ]);
                                }}
                                height={25}
                                width={25}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveSVModalOpened ? (
                <ConfirmationArchive
                    data={{
                        setIsArchiveSVModalOpened,
                        isArchiveSVModalOpened,
                        setIsValidateClicked,
                    }}
                />
            ) : null}
            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}
