import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader } from "reactstrap";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { setGlobalDataIsUpdated } from "../Applications/store";
import { store as settingPrinter } from "./store";
import {
    setIsConfigurationPrinterClicked,
    store,
} from "../Applications/Kiosk/store";
import { setTypeLogoCustomer } from "./General/Hook/store";
import { MenuWrapper } from "../Applications/MenuWrapperConfiguration";

import { capitalize } from "@helpers/general";

import "@assets/swal.css";
import "@assets/theme.css";
import "./index.css";

type ConfigurationPrinterType = {
    rowData: any;
    oneShop: boolean;
};

export default function ApplicationSetting({
    rowData,
    oneShop,
}: ConfigurationPrinterType) {
    const { t } = useTranslation();
    const { isValidated } = useSnapshot(settingPrinter);
    const { isConfigurationPrinterClicked, displayMode } = useSnapshot(store);

    useEffect(() => {
        if (isValidated) {
            setGlobalDataIsUpdated(true);
        }
        // eslint-disable-next-line
    }, [isValidated, setGlobalDataIsUpdated]);

    return (
        <Modal
            centered
            backdrop="static"
            fade={false}
            scrollable
            className="modal-wrapper-width__clz"
            isOpen={isConfigurationPrinterClicked}
        >
            <ModalHeader
                toggle={() => {
                    setIsConfigurationPrinterClicked!(
                        !isConfigurationPrinterClicked
                    );
                    //setGlobalDataIsUpdated(true);
                    setTypeLogoCustomer("");
                }}
            >
                {displayMode === t("View by device") ? (
                    <StyledH2>
                        {capitalize(t("Settings"))} {rowData.peripheral} -{" "}
                        {rowData.appName}
                    </StyledH2>
                ) : (
                    <StyledH2>
                        {capitalize(t("Settings"))} {rowData.appName} -{" "}
                        {rowData[t("Peripheral")]}
                    </StyledH2>
                )}
            </ModalHeader>
            <MenuWrapper rowData={rowData} oneShop={oneShop} />
        </Modal>
    );
}
