import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { diff } from "deep-object-diff";

import { store } from "@components/VerticalLayout/store";
import { store as storeKds } from "../ModalContentWrapper/store";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";
import { setKey } from "../store";

type PropsType = {
    children: React.ReactNode;
    setIsConfigurationModalOpened: Function;
    isConfigurationModalOpened: boolean;
    rowData: any;
    setIsDataUpdated: Function;
    setIsModalConfirmationConfigurationKds: Function;
};

export function ModalWrapper({
    children,
    setIsConfigurationModalOpened,
    isConfigurationModalOpened,
    rowData,
    setIsDataUpdated,
    setIsModalConfirmationConfigurationKds,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop } = useSnapshot(store);
    const {
        design,
        language,
        beeper,
        view,
        template,
        isConfigured,
    } = useSnapshot(storeKds);
    const uuidUser = localStorage.getItem("uuidUser");
    async function handleUpdateProjectButtonOnClickEvent() {
        let apiUrl: any;

        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/kds`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: rowData.projectId,
                    shopId:
                        oneShop && Object.keys(rowData.template).length === 0
                            ? "0"
                            : rowData.shopId,
                    operatorId: operatorID,
                    uuidUser :uuidUser ,
                    data: {
                        template: {
                            design: design,
                            language: language,
                            beeper: beeper,
                            view: view,
                        },
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    !oneShop &&
                        toast.success(
                            `${t("KDS configuration saved successfully")}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    if (
                        rowData !== undefined &&
                        Object.keys(rowData).length > 0 &&
                        Object.keys(rowData?.template).length !== 0 &&
                        rowData?.subRows.length > 0 &&
                        rowData?.subRows.some(
                            (el: any) => Object.keys(el.template).length !== 0
                        ) &&
                        diff(template, {
                            design: design,
                            language: language,
                            beeper: beeper,
                            view: view,
                        }) !== undefined
                    ) {
                        setIsModalConfirmationConfigurationKds(true);
                    } else {
                        setIsDataUpdated(true);
                    }

                    setIsConfigurationModalOpened(!isConfigurationModalOpened);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function handleUpdateAssociateShopButtonOnClickEvent(
        shopId: any,
        idProject: any
    ) {
        let apiUrl: any;
        let savedData = {
            userId: userID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            franchiseId: franchiseID,
            shopId: shopId,
            idProject: idProject,
            template: {
                design: design,
                language: language,
                beeper: beeper,
                view: view,
            },
        };
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/associated/kds`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("KDS configuration saved successfully")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function handleValidate() {
        let localData: any = [];
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/kds`;
        if (rowData.subRows.length !== 0) {
            for (let el of rowData.subRows) {
                let savedData: any = {
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: el.projectId,
                    shopId: el.id_boutique,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        template: {
                            design: design,
                            language: language,
                            beeper: beeper,
                            view: view,
                        },
                    },
                };

                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            }
        }
    }

    async function handleUpdateShopButtonOnClickEvent() {
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/kds`;
        toast.dismiss();
        let localData: any = [];
        let arrayOfShops: any =
            rowData.associates !== undefined &&
            Object.keys(rowData.associates).length !== 0
                ? Object.keys(rowData.associates)
                : [rowData.id_boutique];
        if (
            Object.keys(template).length !== 0 &&
            diff(template, {
                design: design,
                language: language,
                beeper: beeper,
                view: view,
            }) === undefined
        ) {
            setIsConfigurationModalOpened(!isConfigurationModalOpened);
        } else {
            arrayOfShops.forEach((el: any) => {
                localData.push(
                    fetch(apiUrlPlan, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: userID,
                            franchiseId: franchiseID,
                            project_id: "PROJECT",
                            projectId: rowData.projectId,
                            shopId: el,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            data: {
                                template: {
                                    design: design,
                                    language: language,
                                    beeper: beeper,
                                    view: view,
                                },
                            },
                        }),
                    }).then((response) => response.json())
                );
            });
            try {
                mutate(
                    apiUrlPlan,
                    await Promise.all(localData).then(async (result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            if (
                                rowData !== undefined &&
                                Object.keys(rowData).length > 0 &&
                                Object.keys(rowData?.template).length !== 0 &&
                                rowData?.subRows.length > 0 &&
                                rowData?.subRows.some(
                                    (el: any) =>
                                        Object.keys(el.template).length !== 0
                                ) &&
                                diff(template, {
                                    design: design,
                                    language: language,
                                    beeper: beeper,
                                    view: view,
                                }) !== undefined
                            ) {
                                setIsModalConfirmationConfigurationKds(true);
                            } else {
                                setKey(uuid());
                            }
                            handleUpdateAssociateShopButtonOnClickEvent(
                                rowData.id_boutique,
                                rowData.projectId
                            );
                            setIsConfigurationModalOpened(
                                !isConfigurationModalOpened
                            );
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function handleApplicationValidateButtonClickEvent() {
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/template`;
        if (
            Object.keys(template).length !== 0 &&
            diff(template, {
                design: design,
                language: language,
                beeper: beeper,
                view: view,
            }) === undefined
        ) {
            setIsConfigurationModalOpened(!isConfigurationModalOpened);
        } else {
            let savedData = {
                userId: userID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                franchiseId: franchiseID,
                shopId: rowData.shopId,
                appId: "KDS",
                appId_children: rowData.appId,
                template: {
                    [oneShop ? rowData.id_project : rowData.projectId]: {
                        design: design,
                        language: language,
                        beeper: beeper,
                        view: view,
                    },
                },
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then(async (data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }

                            toast.success(
                                `${t("KDS configuration saved successfully")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsConfigurationModalOpened(
                                !isConfigurationModalOpened
                            );
                            setIsDataUpdated(true);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("Mistake ! Please try again")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                isOpen={isConfigurationModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={() => {
                        setIsConfigurationModalOpened(
                            !isConfigurationModalOpened
                        );
                        setIsDataUpdated(true);
                    }}
                    className="text-uppercase"
                >
                    {t("KDS Setup")}
                </ModalHeader>
                <ModalBody className="p-0">{children}</ModalBody>
                <ModalFooter>
                    <CustomMainColorButton
                        className="w-20 kds_btn_validateTemplate"
                        rounded
                        variant="primary"
                        disabled={!isConfigured}
                        onClick={() => {
                            if (oneShop) {
                                if (rowData.key_project !== undefined) {
                                    handleUpdateShopButtonOnClickEvent();
                                    if (
                                        Object.keys(rowData.template).length ===
                                        0
                                    ) {
                                        handleUpdateProjectButtonOnClickEvent();
                                    }
                                } else {
                                    handleApplicationValidateButtonClickEvent();
                                }
                            } else {
                                if (rowData.key_project !== undefined) {
                                    if (rowData[t("Status")] !== "Publié") {
                                        handleUpdateProjectButtonOnClickEvent();
                                        if (
                                            Object.keys(rowData.template)
                                                .length === 0
                                        ) {
                                            handleValidate();
                                        }
                                    } else {
                                        setIsConfigurationModalOpened(
                                            !isConfigurationModalOpened
                                        );
                                        setIsDataUpdated(true);
                                    }
                                } else if (
                                    rowData.key_application === undefined
                                ) {
                                    handleUpdateShopButtonOnClickEvent();
                                } else {
                                    handleApplicationValidateButtonClickEvent();
                                }
                            }
                        }}
                    >
                        {rowData[t("Status")] === "Publié" &&
                        rowData.key_project !== undefined
                            ? t("Close")
                            : t("Save")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
