import React from "react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SettingsIcon } from "@aureskonnect/react-ui";

import { setDataModal } from "../store";

import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import ModalPass from "../ModalPass";
import Look from "@components/Common/SvgIcons/Look";
import ModalTag from "../MadalTag";

type CustomIconsType = {
    e: any;
    setIsAddCustomerAccountModalClicked: Function;
    isAddCustomerAccountModalClicked: boolean;
    setCostumerName: Function;
    setDataIsUpdated: Function;
    dataIsUpdated: number | boolean;
};
export function CustomIcons({
    e,
    setIsAddCustomerAccountModalClicked,
    isAddCustomerAccountModalClicked,
    setCostumerName,
    setDataIsUpdated,
    dataIsUpdated,
}: CustomIconsType) {
    const { t } = useTranslation();
    const [modalPass, setModalPass] = React.useState<boolean>(false);
    const [isModalTagOpened, setIsModalTagOpened] = React.useState<boolean>(
        false
    );
    const [uid, setUid] = React.useState<string>("");

    return (
        <React.Fragment>
            <div className="d-flex justify-content-around ">
                <Tooltip title={t("To consult").toString()}>
                    <div className="py-2 pr-2">
                        <ConsultSvgIcon
                            className="cmn_icn_toConsult"
                            height={35}
                            width={35}
                            onClick={() => {
                                e.selectedRow["Société"] =
                                    e.selectedRow[t("Shop")];
                                setDataModal(e.selectedRow);
                                setIsAddCustomerAccountModalClicked(
                                    !isAddCustomerAccountModalClicked
                                );
                                setCostumerName(e.selectedRow[t("Shop")]);
                                setUid(e.selectedRow.uid);
                            }}
                            style={{
                                cursor: "pointer",
                                position: "relative",
                                bottom: "5px",
                            }}
                        />
                    </div>
                </Tooltip>
                <Tooltip title={t("Modify passcode")}>
                    <div className="py-2">
                        <Look
                            height={40}
                            width={40}
                            fill="black"
                            style={{
                                cursor: "pointer",
                            }}
                            className="pointer__clz cmn_icn_BThistory cmn_icn_history"
                            onClick={() => {
                                setModalPass(true);
                                setUid(e.selectedRow.uid);
                            }}
                        />
                    </div>
                </Tooltip>
                <Tooltip title={t("configure")}>
                    <div className="py-2">
                        <SettingsIcon
                            onClick={() => {
                                setIsModalTagOpened(true);
                            }}
                            height={22}
                            width={22}
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    </div>
                </Tooltip>
                {modalPass ? (
                    <ModalPass
                        isModalPassClicked={modalPass}
                        setIsModalPassClicked={setModalPass}
                        uid={uid}
                    />
                ) : null}
                {isModalTagOpened ? (
                    <ModalTag
                        isModalOpened={isModalTagOpened}
                        setIsModalOpened={setIsModalTagOpened}
                        rowData={e}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        tagList={
                            e.selectedRow.Tag.map(
                                (el: any) => el.designation
                            ) ?? []
                        }
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
}
