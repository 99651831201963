import React from "react";
import {
    StyledH2,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import {
    AvForm,
    AvGroup,
    AvRadioGroup,
    AvRadio,
} from "availity-reactstrap-validation";

export function General({ design, setDesign, rowData }: any) {
    const { t } = useTranslation();
    const numbers: {
        label: string;
        value: string;
    }[] = ["8,4,2", "6,6,1", "4,4,1"].map((element: string) => {
        let array = element.split(",");
        return {
            label: `${array[0]} (${array[1]} ${t("columns")}, ${array[2]} ${t(
                "rows"
            )})`,
            value: element,
        };
    });
    const [designation, setDesignation] = React.useState<string>(
        design.general.designation
    );
    const [mode, setMode] = React.useState<any>(design.general.mode);
    const [number, setNumber] = React.useState<any>(
        design.general.number !== "" ? design.general.number : numbers[0]
    );
    const handleChange = (selected: any) => {
        setMode(selected);
    };
    const handleNumberChange = (number: any) => {
        setNumber(number);
    };

    React.useEffect(() => {
        setDesign(
            JSON.parse(
                JSON.stringify({
                    ...design,
                    general: {
                        designation: designation,
                        mode: mode,
                        number: number,
                    },
                })
            )
        );
        // eslint-disable-next-line
    }, [designation, mode, number]);

    return (
        <React.Fragment>
            <AvForm>
                <AvGroup>
                    <StyledH2 className="ml-3 mt-4 mb-4">
                        {t("Screen name")}
                    </StyledH2>
                    <StyledTextInput
                        name="designation"
                        autocomplete="off"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setDesignation(e.target.value);
                        }}
                        style={{ width: "39%", marginLeft: "1%" }}
                        value={designation}
                        className="kds_inp_screenName"
                    />
                </AvGroup>
                {rowData !== undefined &&
                rowData[t("Type of KDS")] === "Assemblage" ? (
                    <div>
                        <div className="border-footer__clz p-2" />
                        <AvForm
                            model={{
                                form: mode,
                                checkItOut: true,
                            }}
                        >
                            <StyledH2 className="ml-3 mt-4 mb-3">
                                {t("Mode")}
                            </StyledH2>
                            <AvRadioGroup
                                name="form"
                                required
                                inline
                                errorMessage="Pick one!"
                            >
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="ml-5 custom-radio-group">
                                        {t("Landscape")}
                                    </div>
                                    <div className="float-right mr-5">
                                        <AvRadio
                                            id="oneRadio"
                                            value="Landscape"
                                            onChange={() =>
                                                handleChange("Landscape")
                                            }
                                            className="kds_chk_landscapeMode"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="ml-5 custom-radio-group">
                                        {t("Portrait")}
                                    </div>
                                    <div className="float-right mr-5">
                                        <AvRadio
                                            id="twoRadio"
                                            value="Portrait"
                                            onChange={() =>
                                                handleChange("Portrait")
                                            }
                                            classNAme="kds_chk_portraitMode"
                                        />
                                    </div>
                                </div>
                            </AvRadioGroup>
                        </AvForm>
                        <div className="border-footer__clz p-2" />
                        <div>
                            <StyledH2 className="ml-3 mt-4 mb-3">
                                {t("Number of orders displayed per page")}
                            </StyledH2>
                            <div style={{ width: "40%" }}>
                                <StyledSelectInput
                                    id="sales-methods-select"
                                    name="sales-methods-select"
                                    options={numbers}
                                    value={number}
                                    onChange={(e: any) => handleNumberChange(e)}
                                    noOptionsMessage={() =>
                                        t("There's no more choice")
                                    }
                                    className="kds_drp_numberOrdersDisplayedPerPage ml-3"
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </AvForm>
        </React.Fragment>
    );
}
