import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { uuid } from "uuidv4";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
    setIsAssociatedAggregator,
    setIds,
    setMessage,
    setAggregator,
    setAssignRows,
    setKey,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { ConfirmationMessage } from "./ConfirmationMessage";
import { ConfirmationUpdate } from "./ConfirmationUpdate";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type AggregatorAssociateModalType = {
    selectedShopsRows: any;
    setLimit: Function;
};
export function AggregatorAssociateModal({
    selectedShopsRows,
    setLimit,
}: AggregatorAssociateModalType) {
    const { t } = useTranslation();
    let {
        isAssociateModalOpened,
        localShop,
        numberActiveMultiShop,
        idsLocalShop,
        rowsShop,
        selectedRowsAggregator,
        activeAggregator,
        isAssociatedAggregator,
        assignRows,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isValidateClickedModal, setIsValidateClickedModal] = React.useState<
        boolean | number
    >(false);
    const [isStateChanged, setIsStateChanged] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [selectedAggregator, setSelectedAggregator] = React.useState<any[]>(
        []
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const shopId = 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/list/aggregator?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 6,
            columnName: t("Action"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    element={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedAggregator={selectedAggregator}
                />
            ),
        },
    ];

    React.useEffect(() => {
        setAssignRows(dataTable?.data);
    }, [dataTable]);

    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        for (let element of localShop) {
            try {
                mutate(
                    redisApiUrl,
                    await fetch(redisApiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: element,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }

    async function AssociateAggregator() {
        toast.dismiss();
        let isError: boolean = false;
        let isData: boolean = false;
        let localData: any = [];
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            setLimit(1);

            for (let element of localShop) {
                let objectData: any = {};
                for (let index = 0; index < selectedRows.length; index++) {
                    let rowsSel = JSON.parse(JSON.stringify(assignRows));
                    const el = rowsSel?.find((x: any) => {
                        return x.Id === selectedRows[index].Id;
                    });
                    Object.assign(objectData, {
                        [el.Id]: {
                            aggregator: el[t("Aggregator")],
                            webhook: el[t("Webhook")],
                            remark: el[t("Remark")],
                            isActive: el.state,
                            isArchived: el.isArchived,
                            shopId: element,
                            state: el.state,
                            stateShop: el.state,
                            deliverooIdentifier: el.deliverooIdentifier,
                        },
                    });
                }
                let dataAggregator = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: objectData,
                    affectation: true,
                };

                if (Object.keys(dataAggregator.data).length !== 0) {
                    isData = true;
                }
                localData.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(dataAggregator),
                        }
                    ).then((response) => response.json())
                );
            }
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`,
                    await Promise.all(localData).then((result) => {
                        result.forEach((element: any) => {
                            if (element.error === true) setError(true);
                        });
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (error === false) {
                if (isError && !isData) {
                    ErrorToast(
                        t(
                            "Please note that one or more Aggregator could not be assigned. A store cannot have more than one Aggregator setting."
                        )
                    );
                } else if (isData && isError) {
                    toast.success(
                        `${t(
                            "Aggregator assignment was successfully assigned"
                        )!}.`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    ErrorToast(
                        t(
                            "Please note that one or more Aggregator could not be assigned. A store cannot have more than one Aggregator setting."
                        )
                    );
                    RedisPublishPeripheral();
                    setIsValidateButtonDisabled(false);
                    setLimit(2);
                    setKey(uuid());
                } else {
                    toast.success(
                        `${t(
                            "Aggregator assignment was successfully assigned"
                        )!}.`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setKey(uuid());
                }
                RedisPublishPeripheral();
                setNumberActiveMultiShop(
                    numberActiveMultiShop +
                        selectedRows.length * localShop.length
                );
                setIsAssociateModalOpened(!isAssociateModalOpened);
                setIsValidateButtonDisabled(false);
            }
        }
    }
    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }
    function checkIfFiscalYearExistAndArchived() {
        let archived: any[] = [];
        if (rowsShop.length > 0) {
            rowsShop.forEach((element: any) => {
                if (element.archivedAggregator !== undefined) {
                    element.archivedAggregator.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }
    React.useEffect(() => {
        let local: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el?.subRows !== undefined)
                el?.subRows.forEach((element: any) => {
                    local.push(element);
                });
        });
        setSelectedAggregator(local);
        let notChanged: any = [];
        // eslint-disable-next-line
        const dataVerified: any = assignRows?.map((x: any) => {
            if (
                selectedRows
                    ?.map((y: any) => {
                        return y.Id;
                    })
                    ?.indexOf(x.Id) !== -1
            ) {
                return x;
            }
        });

        local.forEach((el: any) => {
            for (let index = 0; index < dataVerified.length; index++) {
                const elx = dataVerified[index];
                if (el?.Id === elx?.Id) {
                    notChanged.push(el.state === elx.state);
                }
            }
        });
        setIsStateChanged(notChanged.includes(false));
        // eslint-disable-next-line
    }, [selectedShopsRows, selectedRowsAggregator, activeAggregator]);

    React.useEffect(() => {
        if (rowsShop[0] !== undefined) {
            if (rowsShop[0]?.subRows !== undefined) {
                localShop.length > 1
                    ? setAggregator("")
                    : setAggregator(
                          rowsShop[0]?.subRows[0] !== undefined
                              ? rowsShop[0]?.subRows[0]?.Id
                              : rowsShop[0].Id
                      );
            } else if (rowsShop[1]?.subRows !== undefined) {
                localShop.length > 1
                    ? setAggregator("")
                    : setAggregator(
                          rowsShop[1]?.subRows[1] !== undefined
                              ? rowsShop[1]?.subRows[1]?.Id
                              : rowsShop[0].Id
                      );
            }
        }
    }, [rowsShop, localShop]);
    React.useEffect(() => {
        if (isValidateClickedModal === true) {
            AssociateAggregator();
        }
        // eslint-disable-next-line
    }, [isValidateClickedModal]);
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setKey(uuid());
                }}
            >
                <span
                    className="text-uppercase fed_txt_AFloyaltyList"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("List of Aggregator")}
                </span>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    customSelect
                    canSelect
                    setData={setDataTable}
                    setSelectedRows={setSelectedRows}
                    canResize
                    canMovedCheckboxLeftOnExpand
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="associateAggregator"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="agr_btn_AFcancel"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setKey(uuid());
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="agr_btn_AFvalidate"
                    onClick={() => {
                        let archived = checkIfFiscalYearExistAndArchived();
                        CountIds();
                        if (isStateChanged) {
                            setIsModalOpened(!isModalOpened);
                        } else {
                            try {
                                let hasArchivedItems: boolean = false;
                                for (
                                    let index = 0;
                                    index < selectedRows.length;
                                    index++
                                ) {
                                    const el = selectedRows[index];
                                    if (archived.includes(el.Id)) {
                                        hasArchivedItems = true;
                                        index = selectedRows.length - 1;
                                    }
                                }
                                if (hasArchivedItems === true) {
                                    setIsAssociatedAggregator(true);
                                    setMessage(
                                        t(
                                            "Attention! One or more selected Aggregator are already assigned and archived at the store level.\nWould you unarchive them?"
                                        )
                                    );
                                    // eslint-disable-next-line
                                    throw "Break";
                                }

                                AssociateAggregator();
                            } catch (e) {
                                if (e !== "Break") throw e;
                            }
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        "Warning! This change will affect specific settings in the store(s).\n\nDo you want to confirm the modification?"
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
            {isAssociatedAggregator ? (
                <ConfirmationUpdate
                    isModalOpened={isAssociatedAggregator}
                    setIsModalOpened={setIsAssociatedAggregator}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
        </StyledModal>
    );
}
